
// Font families start

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
$ubuntu-font-family: 'Ubuntu', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
$lora-font-family: 'Lora', serif;

// Font families end

// Primary colors start

$p1: #A68338;
$p2: #33322B;
$p3: #D2C19B;
$p4: #EAE1CF;
$p5: #F9F6F1;
$p6: #F2BF5A;

// Primary colors end

// Neutral colors start

$n1: #192024;
$n2: #282D33;
$n3: #393C3F;
$n4: #6A6D70;
$n5: #CDCDCD;
$n6: #FFFFFF;
$n7: #BABDBF;

// Neutral colors end

$border-radius: 8px;

@mixin ubuntuH1Styles() {
    font-size: 56px;
    line-height: 60px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
}

@mixin ubuntuH2Styles() {
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
}

@mixin ubuntuH3Styles() {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
}

@mixin ubuntuH4Styles() {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 500;
}

@mixin ubuntuH5Styles() {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
}

@mixin ubuntuP1Styles() {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
}

@mixin ubuntuP2Styles() {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
}

@mixin ubuntuLabelStyles() {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 300;
}

@mixin ubuntuSmallStyles() {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 300;
}

@mixin ubuntuBtnStyles() {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 600;
}

@mixin ubuntuInputStyles() {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
}


@mixin loraH1Styles() {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 40px;
    font-family: $lora-font-family;
}

@mixin loraH2Styles() {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 40px;
    font-family: $lora-font-family;
}

@mixin loraH3Styles() {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 40px;
    font-family: $lora-font-family;
}

@mixin loraParaStyles() {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 40px;
    font-family: $lora-font-family;
}

@mixin loraSmallStyles() {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 40px;
    font-family: $lora-font-family;
}


@mixin iconStyles() {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
}

@mixin btnSpacing() {
    padding: 14px 20px;
}

// Buttons styles start

// Black to light golden
@mixin btn1Styles() {
    color: $p6;
    background-color: transparent;
    border: 1px solid $p1;
}

@mixin btn1HoverStyles() {
    color: $p6;
    background-color: rgba(166, 131, 56, 0.1);
    border: 1px solid $p1;
}

// Golden to white
@mixin btn2Styles() {
    color: $n6;
    background-color: $p1;
}

@mixin btn2HoverStyles() {
    color: $p1;
    background-color: $n6;
}

// Transparent to white
@mixin btn3Styles() {
    color: $n6;
    background-color: transparent;
    border: 1px solid $n6;
}

@mixin btn3HoverStyles() {
    color: $n1;
    background-color: $n6;
    border: 1px solid $n6;
}

// Transparent to golden
@mixin btn4Styles() {
    color: $n1;
    background-color: transparent;
    border: 1px solid $p1;
}

@mixin btn4HoverStyles() {
    color: $n6;
    background-color: $p1;
    border: 1px solid $p1;
}

// Buttons styles end
