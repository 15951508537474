
#my-collection {
    background-color: $n1;
    position: relative;
    color: $n6;
    .my-btn1 {
        @media screen and (max-width: 767.98px) {
            width: 100%;
            padding: 10px;
        }
        .arrow-buy {
            display: inline-block;
            width: 30px;
            margin-left: 10px;
        }
    }
    .all-cards {
        display: flex;
        flex-wrap: wrap;
        max-width: 1000px;
        margin: auto;
        .item-card {
            background-color: #000;
            color: $n6;
            border: 1px solid #000;
            border-radius:8px 8px 20px 20px;
            .purple {
                background-color: #A35592;
                border-radius: 16px 16px 0 0;
            }
            img {
                object-fit: cover;
                border-radius: 8px 8px 0 0;
                opacity: 1;
                @media screen and (max-width: 767.98px) {
                    border-radius: 16px 16px 0 0;
                }
            }
            video {
                display: block;
                object-fit: cover;
                border-radius: 16px 16px 0 0;
            }
            .detail-box {
                padding: 16px;
                text-align: left;
                background-color: #30373B;
                border-radius: 0 0 20px 20px;
                min-height: 60px;
                @media screen and (max-width: 767.98px) {
                    border-radius: 0 0 40px 40px;
                }
                .card-name {
                    color: #A5823D;
                    font-size: 18px;
                    line-height: 21px;
                    margin: 12px 0;
                    text-decoration: underline;
                    @media screen and (max-width: 767.98px) {
                        font-size: 13px;
                        margin-top: 0;
                    }
                }
                .details {
                    display: flex;
                    justify-content: space-between;
                    .purchase {
                        display: flex;
                        align-items: center;
                        .etherum-icon {
                            @include iconStyles();
                            background-image: url("../images/ethereum1.svg");
                            width: 13px;
                            height: 24px;
                            margin-right: 10px;
                        }
                    }
                    .box {
                        .title {
                            font-size: 12px;
                            line-height: 15px;
                            color: $p4;
                            margin-bottom: 2px;
                        }
                        .price {
                            font-size: 18px;
                            line-height: 21px;
                            text-transform: uppercase;
                        }
                        .token-id {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                    .second {
                        text-align: right;
                    }
                }
            }
            &:hover {
                text-decoration: none;
                border: 1px solid $p1;
                img {
                    opacity: 1;
                }
                .detail-box {
                    .card-name {
                        color: $n6;
                    }
                }
            }
        }
    }
    .my-pagination {
        margin-top: 40px;
        ul {
            li {
                a {
                    width: 60px;
                    height: 60px;
                    background-color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $n6;
                    border: 1px solid transparent;
                    font-size: 18px;
                    &:hover {
                        background-color: rgba(166, 131, 56, 0.1);
                        border: 1px solid $p1;
                    }
                    @media screen and (max-width: 767.98px) {
                        font-size: 13px;
                    }
                }
                &.active {
                    a {
                        color: #F2BF5A;
                    }
                }
                &.previous a:after {
                    content: '';
                    @include iconStyles();
                    background-image: url("../images/previous.svg");
                    width: 20px;
                    height: 13px;
                }
                &.previous.disabled a:after {
                    content: '';
                    @include iconStyles();
                    background-image: url("../images/previous-disabled.svg");
                    width: 20px;
                    height: 13px;
                }
                &.next a:after {
                    content: '';
                    @include iconStyles();
                    background-image: url("../images/next.svg");
                    width: 20px;
                    height: 13px;
                }
                &.next.disabled a:after {
                    content: '';
                    @include iconStyles();
                    background-image: url("../images/next-disabled.svg");
                    width: 20px;
                    height: 13px;
                }
                &:first-child {
                    a {
                        border-radius: 8px 0 0 8px;
                    }
                }
                &:last-child {
                    a {
                        border-radius: 0 8px 8px 0;
                    }
                }
            }
        }
    }
    &.vault, &.gallery, &.collection {
        .all-cards {
            max-width: 100%;
            .item-card {
                border: none;
                &:hover {
                    border: none;
                }
            }
        }
        .sec-sub-heading {
            font-size: 36px;
            line-height: 1.4;
            @media screen and (max-width: 767.98px) {
                font-size: 21px;
                line-height: 1.6;
            }
        }
        .underline {
            border-bottom: 1px solid #000;
        }
        .collection-buy-now {
            .item-card {
                display: block;
                background: transparent;
                border: 1px dashed #A5823D;
                .bg-color {
                    height: 0;
                    padding-bottom: 100%;
                    .collection-plus-icon {
                        padding-top: 40%;
                    }
                }
                .detail-box {
                    background: transparent;
                    border-top: 1px dashed #A5823D;
                    .card-name {
                        margin: 1px;
                        &:first-child {
                            color: #F2BF5A;
                        }
                    }
                }
                &:hover {
                    border: 1px dashed #A5823D;
                }
            }
        }
        .description {
            a {
                color: #F2BF5A;
                text-decoration: underline;
            }
        }
    }
    .react-tabs__tab-list {
        width: fit-content;
        margin: auto;
        border: 1px solid rgb(165 130 61 / 40%);
        border-radius: 40px;
        padding: 4px;
        color: #A5823D;
        margin-bottom: 30px;
        .react-tabs__tab {
            padding: 15px 25px;
            font-size: 16px;
            bottom: 0;
            border: none;
            @media screen and (max-width: 767.98px) {
                padding: 15px;
            }
            &:first-child {
                border-radius: 30px 0 0 30px;
            }
            &:first-child {
                border-radius: 0 30px 30px 0;
            }
            &.react-tabs__tab--selected {
                background: rgb(165 130 61 / 10%);
                border: none;
                color: $p6;
                &:first-child {
                    border-radius: 30px 0 0 30px;
                }
                &:last-child {
                    border-radius: 0 30px 30px 0;
                }
                .white {
                    color: #FFF;
                }
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid $p6;
                    position: absolute;
                    bottom: -12px;
                    left: auto;
                    right: 50%;
                    background: transparent;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #my-collection .all-cards {
        justify-content: center;
    }
}

@media screen and (max-width: 991.98px) {
    #my-collection .object1 {
        width: 700px;
        height: 490px;
    }
}

@media screen and (max-width: 767.98px) {
    #my-collection .object1 {
        width: 450px;
        height: 315px;
    }
    #my-collection .object2 {
        width: 500px;
        height: 800px;
    }
    .my-pagination .pagination {
        flex-wrap: wrap;
        justify-content: center !important;
    }
}

@media screen and (max-width: 575.98px) {
    #my-collection .object1 {
        width: 300px;
        height: 210px;
    }
    #my-collection .object2 {
        width: 300px;
        height: 480px;
    }
    #my-collection .object2 {
        width: 300px;
        height: 480px;
    }
    #my-collection .my-pagination ul li {
        margin-bottom: 20px;
    }
}