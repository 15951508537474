
@import "reset";
@import "variables";
@import "home";
@import "about";
@import "contact-us";
@import "my-collection";
@import "nft-detail-page";
@import "how-to-buy";

html {
    scroll-behavior: smooth;
}

body {
    font-family: $ubuntu-font-family;
    font-size: 14px;
}

p {
    font-family: $ubuntu-font-family;
    font-size: 14px;
    line-height: 22px;
}

a, button, .btn:not(:disabled):not(.disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: url('../images/pointing-hand.svg') 10 10, move;
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1320px;
    }
}

.Fsize_16 {
    font-size: 16px;
}

.Fsize_24 {
    font-size: 24px;
}

.line-height-11 {
    line-height: 1.2;
}

.second-color {
    color: $p6;
}

.third-color {
    color: $n7;
}

.page-heading {
    font-size: 36px;
    line-height: 1;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
    @media screen and (min-width: 992px) {
        font-size: 60px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 80px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 100px;
    }
}

.page-sub-heading {
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: 0;
    font-family: $ubuntu-font-family;
    font-weight: 400;
    color: #BABDBF;
    .sup-text {
        color: $p1;
        vertical-align: super;
        margin-left: 2px;
        font-size: 15px;
    }
    @media screen and (min-width: 992px) {
        font-size: 30px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 40px;
        .sup-text {
            font-size: 20px;
        }
    }
    @media screen and (min-width: 1400px) {
        font-size: 50px;
        .sup-text {
            font-size: 25px;
            margin-left: 3px;
        }
    }
}

section {
    .chinese-text {
        font-family: $lora-font-family;
        color: $p1;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 6px;
        margin-bottom: 6px;
    }
    .sec-heading {
        @include ubuntuH1Styles();
        text-align: center;
    }   
    p.description {
        font-size: 18px;
        line-height: 28px;
        @media screen and (max-width: 767.98px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

// Header styles start

.ImgTraits {
    position: relative;
    .LeftArrow {
        text-align: right;
        position: absolute;
        width: 150px;
        left: -250px;
        top: 0;
        padding-right: 10px;
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-right: 12px solid #4971b8;
            border-bottom: 6px solid transparent;
            position: absolute;
            left: 100%;
            top: 0;
        }
        &:after {
            content: "";
            height: 12px;
            width: 150px;
            border-bottom: solid 1px #4971b8;
            position: absolute;
            right: 2px;
            left: 100%;
            top: 0;
            transform: translateY(-46%);
        }

        &.LeftOne {
            left: -250px;
            top: 70px;
        }
        &.LeftTwo {
            left: -250px;
            top: 160px;
        }
        &.LeftThree {
            left: -250px;
            top: 220px;
            &:after {
                width: 280px;
            }
        }
        &.LeftFour {
            left: -250px;
            top: 340px;
            &:after {
                width: 205px;
            }
        }
        &.LeftFive {
            left: -250px;
            top: 400px;
            &:after {
                width: 212px;
            }
        }
    }
    .RightArrow {
        text-align: left;
        position: absolute;
        width: 150px;
        right: -250px;
        top: 0;
        padding-left: 10px;
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-left: 12px solid #4971b8;
            border-bottom: 6px solid transparent;
            position: absolute;
            right: 100%;
            top: 0;
        }
        &:after {
            content: "";
            height: 12px;
            width: 150px;
            border-bottom: solid 1px #4971b8;
            position: absolute;
            left:auto;
            right: 100%;
            top: 0;
            transform: translateY(-46%);
        }
        &.RightOne {
            right: -250px;
            top: 70px;
        }
        &.RightTwo {
            right: -250px;
            top: 230px;
            &:after {
                width: 350px;
            }
        }
        &.RightThree {
            right: -250px;
            top: 290px;
            &:after {
                width: 280px;
            }
        }
        &.RightFour {
            right: -250px;
            top: 340px;
            &:after {
                width: 240px;
            }
        }
    }
}

.main-navbar {
    background-color: $n1!important;
    position: relative;
    z-index: 1;
    padding: 0;
    .nav-inner-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #000;
        border-radius: 0 0 20px 20px;
        @media screen and (max-width: 768px) {
            border-radius:0 0 40px 40px;
        }
        .navbar-toggler {
            margin-right: 20px;
            padding: 10px;
            border-radius: 50%;
            border-color: #192024;
            .navbar-toggler-icon {
                background-image: url('../images/burger-menu-close.svg');
            }
            &.collapsed .navbar-toggler-icon {
                background-image: url('../images/burger-menu.svg');
            }
        }
    }
    .pages-navigation {
        margin-left: 5px;
        .dropdown {
            .dropdown-toggle {
                width: 32px;
                height: 32px;
                background-color: $n6;
                color: $n1;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .dropdown-menu {
                top: -1px;
                margin: 0;
                left: -1px;
                border-radius: 8px;
                min-width: 217px;
                .dropdown-item {
                    padding: 12px 16px;
                }
            }
        }
    }
    .logo {
        width: 30%;
        text-align: center;
        padding: 20px 0;
        border-right: 1px solid $n1;
        .navbar-brand {
            margin: 0;
        }
    }
    #navbarMain {
        li {
            a {
                color: $n6;
                font-size: 18px;
                &:hover {
                    color: $p1;
                }
                &:focus {
                    outline: none;
                }
            }
            &.active {
                a {
                    color: $p1;
                }
            }
            a.active {
                color: $p1;
            }
            .connected {
                padding: 7px 30px;
                display: block;
            }
            .connect-icon {
                display: inline-block;
                width: 30px;
                margin-left: 10px;
            }
            ul {
                li {
                    margin-left: 60px;
                    @media screen and (max-width: 1200px) {
                        margin-left: 20px;
                    }
                    .dropdown-menu {
                        background-color: $n4;
                        border: 0;
                        margin-top: 8px!important;
                        a {
                            padding: 14px 16px;
                            display: flex;
                            align-items: center;
                            &.dropdown-item {
                                .nav-link {
                                    padding: 0;
                                }
                            }
                            .icon {
                                @include iconStyles();
                                margin-right: 15px;
                                width: 30px;
                                height: 30px
                            }
                            .collection-icon {
                                background-image: url("../images/collection-box.svg");
                            }
                            .transaction-icon {
                                background-image: url("../images/transaction.svg");
                            }
                            &:hover {
                                background-color: $n3;
                                color: $n6;
                            }
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .active {
                    a {
                        color: $p1;
                    }
                }
            }
            &:last-child {
                ul {
                    li {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.my-btn1 {
    @include btn1Styles();
    @include btnSpacing();
    /*
    background: url("../images/button1.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    */
    border-radius: 30px;
    line-height: 1;
    font-size: 16px;
    &:hover {
        @include btn1HoverStyles();
    }
}

.my-btn2 {
    @include btn2Styles();
    @include btnSpacing();
    border-radius: $border-radius;
    line-height: 1;
    font-size: 14px;
    &:hover {
        @include btn2HoverStyles();
    }
}

.my-btn3 {
    @include btn3Styles();
    @include btnSpacing();
    border-radius: $border-radius;
    line-height: 1;
    font-size: 14px;
    &:hover {
        @include btn3HoverStyles();
    }
}

.my-btn4 {
    @include btn4Styles();
    @include btnSpacing();
    border-radius: $border-radius;
    line-height: 1;
    font-size: 14px;
    &:hover {
        @include btn4HoverStyles();
    }
}

.navbar-dark .navbar-nav .nav-link.user {
    padding: 0 !important;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    .user-icon {
        @include iconStyles();
        display: block;
        background-image: url("../images/user.svg");
        width: 70px;
        height: 70px;
    }
    &::after {
        display: none;
    }
}

#backToTop {
    width: 56px;
    height: 48px;
    background-color: $n3;
    border-radius: 8px 0 0 8px;
    position: fixed;
    bottom: 80px;
    right: 0;
    text-align: center;
    .icon {
        @include iconStyles();
        background-image: url("../images/fast-forward-double-right-arrows-symbol.svg");
        width: 20px;
        height: 20px;
        margin-top: 13px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.mt-75 {
    margin-top: -75px;
}

// Header media queries start

@media screen and (max-width: 1400px) {
    .main-navbar .logo {
        width: 25%;
        padding: 20px;
    }
    #navbarMain {
        width: 75%;
    }
}

@media screen and (min-width: 992px) {
    #navbarMain {
        padding: 20px 60px;
    }
}

@media screen and (max-width: 991.98px) {
    .main-navbar .pages-navigation {
        position: absolute;
        top: 20px;
    }
    #navbarMain .align-items-center.w-100 {
        flex-direction: column;
    }
    .main-navbar #navbarMain li ul li {
        margin-right: 0;
        margin-bottom: 20px;
    }
    #navbarMain li.ml-auto {
        margin: auto;
    }
    .main-navbar .pages-navigation .dropdown .dropdown-menu {
        position: absolute;
    }
    .main-navbar #navbarMain li > a, .main-navbar #navbarMain li .nav-item.dropdown > a {
        font-size: 32px;
    }
    .main-navbar #navbarMain li a {
        text-align: center;
    }
    .main-navbar .logo {
        width: 30%;
        border: none;
    }
    .left-on-md {
        order: 1;
    }
    .right-on-md {
        order: 2;
    }
}

@media screen and (max-width: 767.98px) {
    .main-navbar .logo {
        width: 50%;
    }
}

// Header media queries end

// Header styles end

// Footer styles start

#footer {
    position: relative;
    .footer {
        color: $n6;
        .social-icons {
            display: flex;
            @media screen and (max-width: 767.98px) {
                justify-content: space-evenly;
                margin-top: 40px;
                li {
                    margin: 0;
                }
            }
            li {
                margin-right: 16px;
                .icon {
                    @include iconStyles();
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                }
                .medium {
                    img {
                        object-fit: contain;
                        height: 30px;
                    }
                    &:hover {
                        background-color: #66cdaa;
                    }
                }
                .discord {
                    img {
                        object-fit: contain;
                        height: 30px;
                    }
                    &:hover {
                        background-color: #7289da;
                    }
                }
                .telegram {
                    img {
                        object-fit: contain;
                        height: 30px;
                    }
                    &:hover {
                        background-color: #199BE5;
                    }
                }
                .twitter {
                    img {
                        object-fit: contain;
                        height: 30px;
                    }
                    &:hover {
                        background-color: #1DA1F2;
                    }
                }
                .facebook {
                    img {
                        object-fit: contain;
                        height: 34px;
                    }
                    &:hover {
                        background-color: #2f72E3;
                    }
                }
                .opensea {
                    img {
                        object-fit: contain;
                        height: 32px;
                    }
                    &:hover {
                        background-color: #2081E2;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .links {
            li {
                margin-top: 24px;
                a {
                    color: $n7;
                    font-size: 18px;
                }
                &:hover,
                &.active {
                    a {
                        text-decoration: none;
                        color: #A68338;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        p.sub-heading {
            @include ubuntuP2Styles();
            letter-spacing: 0px;
            color: $n7;
            font-size: 18px;
            margin: 20px 0;
        }
        p.description {
            max-width: 420px;
        }
        #subscribeForm, #mc-embedded-subscribe-form {
            .icon {
                @include iconStyles();
                width: 16px;
                height: 16px;
                position: absolute;
                top: 17px;
                left: 16px;
            }
            .at {
                background-image: url("../images/at.svg");
            }
        }
        .my-btn2 {
            margin-top: 16px;
            font-weight: 700;
        }
        .coprights {
            border-top: 1px solid $n1;
            padding: 40px 0;
            margin-top: 30px;
            p {
                font-family: $ubuntu-font-family;  
                color: #6B7378;  
                font-size: 13px;
                .primary-color {
                    color: $p1;
                    .white {
                        color: $n6;
                    }
                }
            }
        }
    }
    .dark {
        /* background-color: $n1; */
        background-color: #000;
        .social-icons {
            li  {
                .icon {
                    background-color: $n2;
                }
            }
        }
        .my-input-with-icon {
            background-color: $n2;
        }
    }
    .light {
        /* background-color: $n2; */
        background-color: #000;
        .social-icons {
            li  {
                .icon {
                    background-color: $n2;
                }
            }
        }
        .my-input-with-icon {
            background-color: $n2;
        }
    }
}

.my-input-with-icon {
    padding: 16px 16px 16px 48px;
    background-color: $n2;
    border-radius: $border-radius;
    border: 0;
    color: $n6;
    &::placeholder {
        color: $n4;
    }
}

// Footer media queries start

.heading-color2 {
    color: $n7;
}
.heading-color3 {
    color: $p6;
}
.heading-color4 {
    color: #A5823D;
}
.heading-color5 {
    color: #FFFFFF;
}

.header-card {
    background: #000000;
    .sec-heading {
        font-size: 50px;
        line-height: 1.2;
        @media screen and (max-width: 991.98px) {
            font-size: 40px;
        }
        @media screen and (max-width: 767.98px) {
            font-size: 25px;
        }
    }
    .sec-heading2 {
        font-size: 36px;
        color: #A5823D;
        @media screen and (max-width: 991.98px) {
            font-size: 24px;
        }
        @media screen and (max-width: 767.98px) {
            font-size: 15px;
        }
    }
    .sec-heading3 {
        font-size: 72px;
        line-height: 1.2;
        @media screen and (max-width: 991.98px) {
            font-size: 40px;
        }
        @media screen and (max-width: 767.98px) {
            font-size: 25px;
        }
    }
}

#about {
    .sec-heading {
        font-size: 50px;
        line-height: 1.2;
        @media screen and (max-width: 991.98px) {
            font-size: 40px;
        }
        @media screen and (max-width: 767.98px) {
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 991.98px) {
    #footer p.sub-heading {
        margin-top: 60px;
    }
    .mt-75 {
        margin-top: 0;
    }
}

@media screen and (max-width: 767.98px) {
    #footer .links,
    #footer p.sub-heading {
        margin-top: 40px;
    }
    .mt-75 {
        margin-top: -75px;
    }
    section .sec-heading {
        font-size: 40px;
    }
}

@media screen and (max-width: 575.98px) {
    section .sec-heading {
        font-size: 36px;
        line-height: normal;
    }
    #footer .links,
    #footer p.sub-heading {
        margin-top: 40px;
    }
    .mt-75 {
        margin-top: 0;
    }
}

// Bless Box 
.bulk-carousel {
    position: relative;
    display: inline-block;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-left: 15px;
  }
  
  .bless {
    background-color: #192024;
  }
  z
  .slide {
    opacity: 0;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 2s;
  }
  
  .image {
    border-radius: 10px;
  }
  
// Footer styles end

.img-placeholder {
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
      background: #000000;
    }
  }
  
  .container2 {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
  
    .ImgTraits {
      position: relative;
  
      .img-wrapper {
        width: 45%;
        margin: 0 auto;
        border-radius: 8px 8px 20px 20px;
        overflow: hidden;
  
        img {
          display: block;
          width: 100%;
        }
      }
      .callout {
        position: absolute;
        
        .inner {
          Text-align: left;
          font-size: 10px;
          margin-bottom: 2px;
        }
        .pointer {
          &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            margin-left: auto;
            border-radius: 50%;
            background: #ffffff;
          }
          &::after {
            content: '';
            display: block;
            height: 1px;
            margin-right: 4px;
            background: #F2BF5A;
            margin-top: -4px;
          }
        }
      }
      .callout.left {
        left: 0;
  
        .inner {
          &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #A5823D;
            opacity: 0.4;
            margin-right: 5px;
          }
        }
      }
      .callout.right {
        right: 0;
  
        .inner {
          text-align: right;
  
          &::after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #A5823D;
            opacity: 0.4;
            margin-left: 5px;
          }
        }
        .pointer {
          &::before {
            margin-left: 0;
            margin-right: auto;
          }
          &::after {
            margin-left: 5px;
            margin-right: auto;
          }
        }
      }
      
      .callout.a {
        top: 9%;
        width: 30%;
      }
      .callout.b {
        top: 27%;
        width: 35%;
      }
      .callout.c {
        top: 44%;
        width: 44.41%;
      }
      .callout.d {
        top: 60%;
        width: 39%;
      }
      .callout.e {
        top: 78%;
        width: 41%;
      }

      .callout.f {
        top: 9%;
        width: 30%;
      }
      .callout.g {
        top: 27%;
        width: 50%;
      }
      .callout.h {
        top: 48.5%;
        width: 46%;
      }
      .callout.i {
        top: 64.8%;
        width: 42.6%;
      }
      .callout.j {
        top: 80.9%;
        width: 30.83%;
      }
    }
  }

  .container3 {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
  
    .ImgTraits {
      position: relative;
  
      .img-wrapper {
        width: 45%;
        margin: 0 auto;
        border-radius: 8px 8px 20px 20px;
        overflow: hidden;
  
        img {
          display: block;
          width: 100%;
        }
      }
      .callout {
        position: absolute;
        
        .inner {
          text-align: left;
          font-size: 10px;
          margin-bottom: 2px;
        }
        .pointer {
          &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            margin-left: auto;
            border-radius: 50%;
            background: #ffffff;
          }
          &::after {
            content: '';
            display: block;
            height: 1px;
            margin-right: 4px;
            background: #F2BF5A;
            margin-top: -4px;
          }
        }
      }
      .callout.left {
        left: 0;
  
        .inner {
          &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #A5823D;
            opacity: 0.4;
            margin-right: 5px;
          }
        }
      }
      .callout.right {
        right: 0;
  
        .inner {
          text-align: right;
  
          &::after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #A5823D;
            opacity: 0.4;
            margin-left: 5px;
          }
        }
        .pointer {
          &::before {
            margin-left: 0;
            margin-right: auto;
          }
          &::after {
            margin-left: 5px;
            margin-right: auto;
          }
        }
      }
      
      .callout.a {
        top: 12.55%;
        width: 50.83%;
      }
      .callout.b {
        top: 29.22%;
        width: 43%;
      }
      .callout.c {
        top: 48.0%;
        width: 53.2%;
      }
      .callout.d {
        top: 64.4%;
        width: 32%;
      }
      .callout.e {
        top: 81.0%;
        width: 50.83%;
      }
      //---------------------------------------
      .callout.f {
        top: 8.85%;
        width: 30%;
      }
      .callout.g {
        top: 25.5%;
        width: 45.9%;
      }
      .callout.h {
        top: 40.33%;
        width: 38.4%;
      }
      .callout.i {
        top: 55.14%;
        width: 34.25%;
      }
      .callout.j {
        top: 84.7%;
        width: 30.83%;
      }
    }
  }
  
  @media (min-width: 992px) {
    .container2 {
      max-width: 960px;
  
      .ImgTraits {
        .callout {
          .inner {
            font-size: 24px;
          }
          .pointer {
            &::before {
              width: 21px;
              height: 21px;
            }
            &::after {
              margin-top: -11px;
            }
          }
        }
      }
    }
    .container3 {
        max-width: 960px;
    
        .ImgTraits {
          .callout {
            .inner {
              font-size: 24px;
            }
            .pointer {
              &::before {
                width: 21px;
                height: 21px;
              }
              &::after {
                margin-top: -11px;
              }
            }
          }
        }
      }
  }
  @media (min-width: 1200px) {
    .container2 {
      max-width: 1140px;
    }
    .container3 {
        max-width: 1140px;
      }
  }
  @media (min-width: 1400px) {
    .container2 {
      max-width: 1320px;
    }
    .container3 {
        max-width: 1320px;
      }
  }

/* MailChimp Form Embed Code - Slim - 12/15/2015 v10.7 */
#mc_embed_signup form {display:block; position:relative; text-align:left;}
#mc_embed_signup h2 {font-weight:bold; padding:0; margin:15px 0; font-size:1.4em;}
#mc_embed_signup input {-webkit-appearance:none;}
#mc_embed_signup input[type=checkbox]{-webkit-appearance:checkbox;}
#mc_embed_signup input[type=radio]{-webkit-appearance:radio;}
#mc_embed_signup input:focus {border-color:#333;}
#mc_embed_signup .button {clear:both; background-color: #aaa; border: 0 none; border-radius:4px; letter-spacing:.03em; color: #FFFFFF; cursor: pointer; display: inline-block; font-size:15px; height: 32px; line-height: 32px; margin: 0 5px 10px 0; padding:0; text-align: center; text-decoration: none; vertical-align: top; white-space: nowrap; width: auto; transition: all 0.23s ease-in-out 0s;}
#mc_embed_signup .button:hover {background-color:#777;}
#mc_embed_signup .small-meta {font-size: 11px;}
#mc_embed_signup .nowrap {white-space:nowrap;}     
#mc_embed_signup .clear {clear:none; display:inline;}

#mc_embed_signup label {display:block; font-size:16px; padding-bottom:10px; font-weight:bold;}
#mc_embed_signup input.email {
    width: 100%;
    background-color: $n1;
    padding: 16px 12px;
    border-radius: 8px;
    border: 0;
    color: #6B7378;
    font-size: 16px;
    margin-bottom: 20px;
}
#mc_embed_signup input.button {display:block; width:35%; margin:0 0 10px 0; min-width:90px;}
#mc_embed_signup button .arrow-buy {display: inline-block; width: 30px; margin-left: 10px;}

#mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%;margin: 0 5%; clear: both;}
#mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {color:#529214; display:none;}
#mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}

/******* Updated from 10/21/2021 ********/
.s2-bg {
    background-color: #19202a;
}
.main-navbar #navbarMain li ul li .s2-bg {
    background-color: #19202a;
}
.metamask-icon {
    background-image: url('../images/metamask_logo.svg');
}
.phantom-icon {
    background-image: url('../images/phantom_logo.png');
}
.wallet-connected-wapper {
    width: 157px;
    display: flex;
    justify-content: space-between;
    .icon {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 30px;
        height: 30px;
    }
}
.wallet-connected-status {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin-top: 8px;
    margin-left: 10px;
    .metamask-icon ~ &.on {
        margin-left: 7px;
    }
    &.on {
        background-color: #21e56f;
    }
    &.off {
        background-color: #eb3742;
    }
}
.span-minting {
    height: 30px;
    display: block;
    padding-top: 5px;
}
.dark-modal {
    background-color: #30373B;
    color: #ffffff;
}
#claim_solana_wallet {
    width: 100%;
    padding: 20px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #6B7378;
    color: #FFFFFF;
    font-size: 16px;
}

.minting-spinner {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    position: relative;
}
  
.minting-spinner-text {
    font-size: 2rem;
    animation: opaque 2000ms ease-in-out infinite;
}
  
.minting-spinner-component {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid transparent;
    // mix-blend-mode: hue;
}
  
.red {
    border-top: 10px solid #ffffff12;
    animation: load 1300ms linear infinite;
    z-index: 4;
}
  
.green {
    border-top: 10px solid #f1f1f178;
    animation: load 1600ms ease-out infinite;
    z-index: 3;
}
  
.blue {
    border-top: 10px solid #00ff0005;
    animation: load 1900ms ease-in infinite;
    z-index: 2;
}
  
@keyframes load {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
}
  
@keyframes opaque {
    0%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.1;
    }
}
.full-width {
    width: 100%;
}