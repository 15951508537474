
#about {
    background-color: $n1;
    position: relative;
    color: $n6;
    .float-img {
        position: absolute;
    }
    .object1 {
        @include iconStyles();
        background-image: url("../images/object1.svg");
        top: 0;
        right: 0;
        width: 818px;
        height: 572px;
    }
    .object2 {
        @include iconStyles();
        background-image: url("../images/about-object2.svg");
        top: 52.5%;
        bottom: -35px;
        left: 0;
        width: 622px;
        height: 995px;
    }
    .about-card {
        .bg-quote-icon {
            @include iconStyles();
            background-image: url("../images/straight-quotes.svg");
            width: 310px;
            height: 240px;
            position: absolute;
            left: 0;
            top: -4px;
            border-top-left-radius: 20px;
        }
        .info {
            text-align: left;
            max-width: 600px;
        }
        .ceo-img {
            display: flex;
            align-items: center;
            height: 100%;
            img {
                border-radius: 8px;
                object-fit: cover;
                width: 100%;
            }
        }
    }
    .nft-info {
        p.description {
            max-width: 1000px;
            margin-bottom: 25px;
        }
    }
    .team {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .item-card {
            background: transparent linear-gradient(270deg, $n1 0%, $n2 100%) 0% 0% no-repeat padding-box;
            border-radius: 16px;
            padding: 24px;
            text-align: center;
            width: 290px;
            height: 290px;
            margin : 0 15px;
            position: relative;
            margin-bottom: 40px;
            .float-img {
                position: absolute;
                top: 0;
                left: 0;
            }
            .object {
                @include iconStyles();
                background-image: url("../images/feature-object.svg");
                width: 200px;
                height: 140px;
            }
            .item {
                .member {
                    width: 160px;
                    height: 160px;
                    object-fit: contain;
                    border-radius: 50%;
                }
                .sec-heading4 {
                    font-size: 24px;
                    line-height: 28px;
                    margin: 24px 0 11px;
                }
                p.description {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.64px;
                    margin: 0 !important; 
                }
            }
            &:hover {
                background: transparent linear-gradient(270deg, transparent 0%, $p2 100%) 0% 0% no-repeat padding-box;
                .object {
                    background-image: url("../images/feature-object-hover.svg");
                }
            }
        }
    }
    .mission {
        h3 {
            @include ubuntuH4Styles();
            margin: 48px 0 24px;
        }
        p.description {
            max-width: 1000px;
            margin-bottom: 24px;
        }
    }
    #features {
        .item-card {
            background: #000000;
            padding: 10;
            .item {
                text-align: left;
                @media screen and (max-width: 767.98px) {
                    text-align: center;
                }
                .sec-heading4 {
                    color: #ffffff;
                }
                &:hover {
                    .sec-heading4, p.description {
                        left: 0
                    }
                }
                .icon {
                    width: 40px;
                    height: 40px;
                }
                .squares {
                    background-image: url("../images/limited-2.svg");
                }
                .folders {
                    background-image: url("../images/full-ownership.svg");
                }
                .medal {
                    background-image: url("../images/good-merit.svg");
                }
                .transparent {
                    background-image: url("../images/transparent-2.svg");
                }
            }
        }
    }
    #features-2 {
        .features-roadmap-item {
            display: flex;
            width: 100%;
            text-align: left;
            padding: 10px;
            background: #30373B;
            border-radius: 20px;
            color: #A5823D;
            margin-top: 10px;
            .features-roadmap-item-star {
                width: 20px;
                flex: 0 0 20px;
            }
            .features-roadmap-item-text {
                line-height: 1.4;
                margin-left: 8px;
            }
        }
        .table-responsive {
            background: #000000;
            text-align: left;
            .table thead th {
                border: none;
                color: #A5823D;
                vertical-align: middle;
                font-size: 13px;
                &:nth-child(2), &:nth-child(3) {
                    width: 140px;
                    text-align: right;
                    padding: 1rem 2rem;
                    @media screen and (max-width: 767.98px) {
                        width: auto;
                        padding: 0.5rem;
                    }
                }
            }
            .table tbody tr {
                td {
                    border-top: none;
                    border-bottom: 1px solid #192024;
                    color: #ffffff;
                    vertical-align: middle;
                    font-size: 18px;
                    @media screen and (max-width: 767.98px) {
                        font-size: 13px;
                    }
                    &:nth-child(2), &:nth-child(3) {
                        width: 140px;
                        text-align: right;
                        padding: 1rem 2rem;
                        @media screen and (max-width: 767.98px) {
                            width: auto;
                            padding: 0.5rem;
                        }
                    }
                    .eth-color {
                        color: #6B7378;
                    }
                    .roadmap-color {
                        color: #BABDBF;
                        font-size: 13px;
                    }
                    &.no-border {
                        border: none;
                    }
                }
            }
        }
    }
    #my-collection {
        .all-cards {
            display: flex;
            flex-wrap: wrap;
            .item-card {
                background-color: #000;
                color: $n6;
                border: 1px solid #000;
                border-radius:8px 8px 20px 20px;
                img {
                    object-fit: cover;
                    border-radius: 8px 8px 0 0;
                    @media screen and (max-width: 767.98px) {
                        border-radius: 16px 16px 0 0;
                    }
                }
                .detail-box {
                    padding: 16px;
                    text-align: left;
                    background-color: #000;
                    border-radius: 0 0 20px 20px;
                    min-height: 70px;
                    @media screen and (max-width: 767.98px) {
                        border-radius: 0 0 40px 40px;
                    }
                    .card-name {
                        font-size: 18px;
                        line-height: 21px;
                        margin-bottom: 6px;
                    }
                    .details {
                        display: flex;
                        justify-content: space-between;
                        .purchase {
                            display: flex;
                            align-items: center;
                            .etherum-icon {
                                @include iconStyles();
                                background-image: url("../images/ethereum1.svg");
                                width: 13px;
                                height: 24px;
                                margin-right: 10px;
                            }
                        }
                        .box {
                            .title {
                                font-size: 12px;
                                line-height: 15px;
                                color: $p4;
                                margin-bottom: 2px;
                            }
                            .price {
                                font-size: 18px;
                                line-height: 21px;
                                text-transform: uppercase;
                            }
                            .token-id {
                                font-size: 14px;
                                line-height: 16px;
                            }
                        }
                        .second {
                            text-align: right;
                        }
                    }
                }
                &:hover {
                    text-decoration: none;
                    border: 1px solid $p1;
                    background-color: rgba(166, 131, 56, 0.1);
                    .detail-box {
                        background-color: rgba(166, 131, 56, 0.01);
                    }
                }
            }
        }
    }
    .FuturePlanImage {
        max-width: 660px;
        margin: auto;
        img {
            min-height: 120px;
        }
        figcaption {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            .contract-address {
                font-size: 18px;
                @media screen and (max-width: 767.98px) {
                    font-size: 13px;
                }
            }
        }
    }
    #AmuletsReveal {
        .distribution-1 {
            background: #30373B;
            color: #A5823D;
            font-size: 18px;
            line-height: 1.4;
            @media screen and (max-width: 767.98px) {
                font-size: 11px;
                border-radius: 0;
            }
            .example {
                color: #6B7378;
                font-size: 13px;
            }
            .description {
                color: $n7;
                font-size: 13px;
            }
        }
        .distribution-12 {
            border: 2px solid #30373B;
            @media screen and (max-width: 767.98px) {
                border-radius: 0;
            }
            .distribution-12-top {
                border-bottom: 2px solid #30373B;
                .artID-1 {
                    li {
                        width: 40px;
                        height: 40px;
                        background: #30373B;
                        color: $n6;
                        border-radius: 20px;
                        margin: 0 5px;
                        text-align: center;
                        line-height: 40px;
                        @media screen and (max-width: 767.98px) {
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 11px;
                            margin: 4px;
                        }
                    }
                    &:after {
                        content: '';
                        width: 0; 
                        height: 0; 
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid #30373B;
                        position: absolute;
                        bottom: -10px;
                    }
                }
                .artID-label {
                    font-size: 18px;
                    color: $n7;
                    text-align: center;
                    @media screen and (max-width: 767.98px) {
                        font-size: 11px;
                    }
                    &:after {
                        content: '';
                        height: 40px;
                        width: 2px;
                        background: #30373B;
                        display: block;
                        margin: auto;
                        margin-top: 15px;
                        @media screen and (max-width: 767.98px) {
                            height: 70px;
                        }
                    }
                }
                .artID-2 {
                    li {
                        min-width: 40px;
                        height: 40px;
                        background: #30373B;
                        color: $n6;
                        border-radius: 20px;
                        margin: 0 5px;
                        text-align: center;
                        line-height: 40px;
                        @media screen and (max-width: 767.98px) {
                            min-width: 30px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 11px;
                            margin: 4px;
                        }
                        &.lw {
                            min-width: 60px;
                            @media screen and (max-width: 767.98px) {
                                min-width: 50px;
                            }
                        }
                    }
                    &:after {
                        content: '';
                        width: 0; 
                        height: 0; 
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid #30373B;
                        position: absolute;
                        bottom: -10px;
                    }
                }
            }
            .distribution-12-bottom {
                .rare-btn {
                    background: #A5823D;
                    color: $n6;
                    padding: 10px;
                    border-radius: 30px;
                    font-size: 18px;
                    display: inline-block;
                    @media screen and (max-width: 767.98px) {
                        font-size: 11px;
                    }
                }
                .artID-label {
                    font-size: 18px;
                    color: $n7;
                    text-align: center;
                    &:after {
                        content: '';
                        height: 40px;
                        width: 2px;
                        background: #30373B;
                        display: block;
                        margin: auto;
                        margin-top: 15px;
                    }
                    @media screen and (max-width: 767.98px) {
                        font-size: 11px;
                    }
                }
            }
        }
        .img-arrow-down {
            height: 40px;
        }
        .distribution-22 {
            border: 2px solid #30373B;
            font-size: 18px;
            @media screen and (max-width: 767.98px) {
                border-radius: 0;
                font-size: 11px;
            }
            .panel-border-right {
                border-right: 2px solid #30373B;
            }
            .layer-label {
                color: #30373B;
            }
            .layer-title {
                color: $p6;
            }
            .layer-detail {
                color: $n6;
                line-height: 1.2;
            }
        }
        .tire-panel {
            border: 2px solid #30373B;
            font-size: 18px;
            color: $n7;
            min-height: 100px;
            @media screen and (max-width: 767.98px) {
                border-radius: 0;
                font-size: 11px;
            }
        }
        .distribution-42 {
            border: 2px solid #30373B;
            font-size: 18px;
            color: $n6;
            line-height: 1.6;
            @media screen and (max-width: 767.98px) {
                border-radius: 0;
                font-size: 11px;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    #about .about-card .info {
        max-width: 100%;
        margin-top: 40px;
    }
    #about .object1 {
        width: 700px;
        height: 490px;
    }
}

@media screen and (max-width: 767.98px) {
    #about .object1 {
        width: 450px;
        height: 315px;
    }
}

@media screen and (max-width: 575.98px) {
    #about .object1 {
        width: 300px;
        height: 210px;
    }
    #about .object2 {
        width: 300px;
        height: 480px;
    }
    #about .about-card {
        padding: 15px 15px 30px;
    }
    .mission .sec-heading,
    .nft-info .sec-heading {
        font-size: 26px;
        line-height: 38px;
    }
    #about .nft-info {
        padding: 50px 0;
    }
}