
#contact-us {
    padding: 100px 0;
    background-color: $n1;
    position: relative;
    color: $n6;
    .float-img {
        position: absolute;
    }
    .object1 {
        @include iconStyles();
        background-image: url("../images/object1.svg");
        top: 0;
        left: 0;
        width: 818px;
        height: 572px;
        transform: scaleX(-1);
    }
    p.description {
        margin-bottom: 60px;
    }
    .btns {
        li {
            margin-bottom: 30px;
            a {
                padding: 27px 30px;
                background-color: rgba(29, 34, 40, 0.8);
                border: 1px solid $n3;
                color: $n6;
                display: flex;
                max-width: 486px;
                border-radius: 16px;
                margin: 0 auto;
                &:hover {
                    text-decoration: none;
                }
                .icon {
                    @include iconStyles();
                    width: 24px;
                    height: 24px;
                }
                .phone {
                    background-image: url("../images/phone-call.svg");
                }
                .at {
                    background-image: url("../images/at.svg");
                }
                .landmark {
                    background-image: url("../images/location.svg");
                }
                .text {
                    font-size: 20px;
                    line-height: 24px;
                    margin-left: 32px;
                }
            }
        }
    }
    #contactForm {
        padding: 60px;
        border-radius: 16px;
        background: transparent linear-gradient(270deg, $n2 0%, $n1 100%) 0% 0% no-repeat padding-box;
        .form-group {
            margin-bottom: 30px;
            textarea, .my-input-with-icon {
                background-color: $n1;
                border: 1px solid $n2;
                border-radius: $border-radius;
            }
            textarea {
                padding: 12px 50px;
                min-height: 160px;
                margin-bottom: 116px;
                color: $n6;
            }
        }
        .icon {
            @include iconStyles();
            width: 16px;
            height: 16px;
            position: absolute;
            top: 17px;
            left: 32px;
        }
        .user {
            background-image: url("../images/name.svg");
        }
        .at {
            background-image: url("../images/at.svg");
        }
        .phone {
            background-image: url("../images/phone-call.svg");
        }
        .chat {
            background-image: url("../images/Speech_Bubble_48_.svg");
        }
        .my-btn2 {
            width: 360px;
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 991.98px) {
    #contact-us {
        padding: 100px 0;
    }
    #contact-us #contactForm .my-btn2 {
        width: 100%;
    }
    #contact-us #contactForm .form-group textarea {
        margin-bottom: 80px;
    }
    #contact-us .object1 {
        width: 500px;
        height: 350px;
    }
}

@media screen and (max-width: 575.98px) {
    #contact-us #contactForm {
        padding: 60px 20px;
    }
    #contact-us .object1 {
        width: 300px;
        height: 210px;
    }
}