
#buy {
    padding: 100px 0 40px;
    background-color: $n1;
    position: relative;
    color: $n6;
    .float-img {
        position: absolute;
    }
    .object1 {
        @include iconStyles();
        background-image: url("../images/object1.svg");
        top: 0;
        right: 0;
        width: 818px;
        height: 572px;
    }
    .object2 {
        @include iconStyles();
        background-image: url("../images/about-object2.svg");
        bottom: 0;
        left: 0;
        width: 622px;
        height: 995px;
    }
    .buy-card {
        padding: 40px;
        border-radius: 16px;
        position: relative;
        margin-bottom: 60px;
        .info {
            text-align: left;
            max-width: 605px;
            display: flex;
            align-items: center;
            height: 100%;
            .info-inner {
                display: flex;
                flex-direction: column;
            }
            h2 {
                margin: 0;
                margin-bottom: 24px;
            }
            p {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.64px;
                margin-bottom: 20px;
            }
        }
        .video {
            border-radius: 8px;
            display: flex;
            align-items: center;
            height: 100%;
            iframe {
                background-color: $n1;
                border-radius: 8px;
            }
        }
    }
    .buy-card:nth-child(even) {
        background: transparent linear-gradient(270deg, $n2 0%, $n1 100%) 0% 0% no-repeat padding-box;
    }
    .buy-card:nth-child(odd) {
        background: transparent linear-gradient(90deg, $n2 0%, $n1 100%) 0% 0% no-repeat padding-box;
        .info {
            text-align: right;
            margin-left: auto;
        }
    }
}

#faq {
    background-color: $n1;
    color: $n6;
    #faqSearch{
        max-width: 744px;
        margin: 0 auto;
        input {
            background-color: $n1;
            border-radius: 8px;
            padding: 23px 23px 23px 64px;
            font-size: 16px;
            margin-bottom: 50px;
        } 
        .magnifier {
            @include iconStyles();
            background-image: url("../images/search.svg");
            width: 20px;
            height: 20px;
            position: absolute;
            top: 22px;
            left: 24px;
        }
    }
    #faqAccordion {
        max-width: 1000px;
        margin: 0 auto;
        .card {
            border: 0;
            border-radius: 16px;
            margin-bottom: 15px;
            background-color: transparent;
            .card-header {
                background: transparent;
                border: 0;
                padding: 0;
                margin: 0;
                border-bottom: 1px solid #000;
                button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 21px 24px;
                    .question {
                        font-size: 20px;
                        line-height: 24px;
                        color: $p6;
                    }
                    .eye-icon {
                        @include iconStyles();
                        background-image: url("../images/eye-closed.svg");
                        width: 22px;
                        height: 22px;
                        margin-left: 30px;
                        background-size: inherit;
                    }
                    .faq-icon {
                        @include iconStyles();
                        background-image: url("../images/faq-icon.svg");
                        width: 12px;
                        height: 12px;
                        margin-right: 30px;
                        @media screen and (max-width: 767.98px) {
                            margin-right: 12px;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                    }
                    &:focus {
                        outline: 0 !important;
                        box-shadow: none !important;
                        text-decoration: none;
                    }
                }
            }
            .card-body {
                background: #30373B;
                text-align: left;
                position: relative;
                color: $n7;
                max-width: 660px;
                margin: 10px auto;
                .minus-icon {
                    @include iconStyles();
                    background-image: url("../images/minus_white_24dp.svg");
                    width: 20px;
                    height: 20px;
                    background-size: inherit;
                    position: absolute;
                    right: 24px;
                    top: 20px;
                    cursor: pointer;
                }
                .heading {
                    font-size: 18px;
                    line-height: 21px;
                    margin-bottom: 24px;
                    color: $p1;
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.64px;
                    margin-bottom: 24px;
                }
                .gold-bold {
                    font-weight: bold;
                    color:#A5823D;
                }
                .gold-word {
                    color:#A5823D;
                }
                
            }
            &.active {
                .card-header {
                    button {
                        .question {
                            color: $n6;
                        }
                        .eye-icon {
                            @include iconStyles();
                            background-image: url("../images/eye.svg");
                            width: 22px;
                            height: 22px;
                            margin-left: 30px;
                            background-size: inherit;
                        }
                        .faq-icon {
                            @include iconStyles();
                            background-image: url("../images/faq-dot.svg");
                            width: 4px;
                            height: 12px;
                            margin-right: 38px;
                            @media screen and (max-width: 767.98px) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }
        }
        
    }
}

#press {
    background-color: $n1;
    color: $n6;
    .press-item {
        border-bottom: 1px solid #000000;
        .press-icon {
            width: 120px;
            flex: 0 0 120px;
            @media screen and (max-width: 767.98px) {
                width: 75px;
                flex: 0 0 75px;
            }
        }
        .press-text {
            .press-source {
                color: #A5823D;
                font-size: 13px;
                @media screen and (max-width: 767.98px) {
                    font-size: 11px;
                }
            }
            .press-link a {
                color: #F2BF5A;
                font-size: 18px;
                line-height: 1.6;
                @media screen and (max-width: 767.98px) {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .buy-card:nth-child(even) {
        .video {
            margin-top: 20px;
        }
    }
    .buy-card:nth-child(odd) {
        .video {
            margin-top: 20px;
        }
    }
    #buy .buy-card {
        padding: 40px 40px 50px;
    }
    #buy .buy-card:nth-child(odd) .info {
        text-align: left;
        margin: 0;
        margin-right: auto;
    }
    #buy .object1 {
        width: 700px;
        height: 490px;
    }
}

@media screen and (max-width: 767.98px) {
    #buy .object1 {
        width: 450px;
        height: 315px;
    }
    #buy .object2 {
        bottom: -15%;
        width: 470px;
        height: 745px;
    }
}

@media screen and (max-width: 575.98px) {
    #buy .buy-card {
        padding: 30px 15px;
    }
    #buy .object1 {
        width: 300px;
        height: 210px;
    }
    #buy .object2 {
        bottom: -16%;
        width: 300px;
        height: 500px;
    }
    #faq #faqAccordion .card .card-header button .question {
        font-size: 16px;
        line-height: 20px;
    }
    #faq #faqAccordion .card .card-header button .plus-icon {
        display: inline-table;
    }
}