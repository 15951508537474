// Crypto section styles start

.cornerround {
    border-radius:8px 8px 20px 20px;
    @media screen and (max-width: 768px) {
        border-radius:16px 16px 40px 40px;
    }
}

#topcornerround {
    border-top-left-radius:15px;
    border-top-right-radius:15px;
}

#font14 {
    font-size: 14px;
}

p.description {
    color: $n7;
}
  
@keyframes slab {
    0%, 100% {
        transform: scale(1,0.3);
        opacity: 0.4;
    }
    2% {
        transform: scale(1,1);
        opacity: 1;
    }
    8.335% {
        transform: scale(1,0.3);
        opacity: 0.4;
    }
    16.67% {
        transform: scale(1,0.3);
        opacity: 0.4;
    }
    18.67% {
        transform: scale(1,0.3);
        opacity: 1;
    }
    25.005% {
        transform: scale(1,0.3);
        opacity: 0.4;
    }
    50.01% {
        transform: scale(1,0.3);
        opacity: 0.4;
    }
    52% {
        transform: scale(1,0.3);
        opacity: 1;
    }
    58.345% {
        transform: scale(1,0.3);
        opacity: 0.4;
    }
    83.35% {
        transform: scale(1,0.3);
        opacity: 0.4;
    }
    85.35% {
        transform: scale(1,0.3);
        opacity: 1;
    }
}
@keyframes dots {
    0%, 100% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
}

#crypto {
    background-color: $n1;
    position: relative;
    color: $n6;
    .float-img {
        position: absolute;
    }
    .line {
        width: 100%;
        top: 50%;
        border-bottom: 1px solid #000;
        @media screen and (max-width: 768px) {
            bottom: 138px;
        }
    }
    .object1 {
        @include iconStyles();
        background-image: url("../images/object1.svg");
        top: 0;
        right: 0;
        width: 818px;
        height: 572px;
    }
    .object2 {
        @include iconStyles();
        background-image: url("../images/object2.svg");
        bottom: 0;
        left: 0;
        width: 265px;
        height: 185px;
    }
    .cream {
        background-color: #EAE1CF;
        color: $n2;
    }
    .arrow-icon {
        margin: 100px 0 0;
        .icon {
            @include iconStyles();
            background-image: url("../images/right-arrow.svg");
            width: 18px;
            height: 24px;
            margin: 0 auto;
        }
    }
}

 // Crypto section styles end

 // Timer section styles

 #timer {
    background-color: $n1;
    position: relative;
    color: $n6;
    .timer-left-box {
        font-size: 18px;
        a {
            color: $p6;
            line-height: 1.4;
        }
        .eye-icon {
            width: 60px;
            height: 60px;
            border: 1px solid $p6;
            border-radius: 50%;
            padding: 15px;
        }
    }
    .timer-box {
        background: #000;
        .timer-heading-wrapper {
            font-size: 24px;
            @media screen and (max-width: 768px) {
                font-size: 15px;
                line-height: 1.4;
            }
            @media screen and (min-width: 1400px) {
                font-size: 36px;
            }
        }
        .buy-btn-wrapper {
            .my-btn1 {
                width: 190px;
                @media screen and (max-width: 768px) {
                    font-size: 15px;
                    width: 120px;
                    padding: 8px;
                }
                .arrow-buy {
                    display: inline-block;
                    width: 30px;
                    margin-left: 10px;
                }
            }
        }
        .tictac-icon {
            @media screen and (max-width: 768px) {
                width: 60px;
            }
        }
        .counter {
            width: 90px;
            height: 90px;
            margin: auto;
            .circle {
                height: 100%;
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .slab {
                    position: absolute;
                    height: 100%;
                    transform: rotate(calc(14.4deg * var(--order)));
                    .top {
                        width: 1px;
                        height: 15px;
                        background: #F2BF5A;
                        transform-origin: top center;
                        transform: scale(1,0.3);
                        opacity: 0.4;
                        animation-duration: 6s;
                        animation-iteration-count: infinite;
                        animation-delay: calc(0.04s * var(--order));
                        animation-name: slab;
                    }
                }
                .dots {
                    font-size: 36px;
                    transform: translate(0,-5%);
                    vertical-align: top;
                    color: #F2BF5A;
                    opacity: 0;
                    animation: 1s ease-in-out infinite dots;
                }
            }
        }
        
        .time {
            display: flex;
            justify-content: center;
            .box {
                display: flex;
                text-align: left;
                font-size: 30px;
                &::after {
                    content: ":";
                    display: inline-block;
                    margin: 0 12px;
                    align-self: flex-start;
                }
                &:last-child {
                    &::after {
                        content: none;
                    }
                }
                .title {
                    margin-top: 5px;
                    font-size: 16px;
                    line-height: 18px;
                    color: $p6;
                }
            }
        }
    }
}

// Timer section styles end

// Features section styles start

#features {
    background-color: $n1;
    position: relative;
    color: $n6;
    .item-card {
        background: #000000;
        padding: 25px;
        height: 100%;
        &.min-height-auto {
            min-height:auto;
        }
        .float-img {
            position: absolute;
            top: 0;
            left: 15px;
        }
        .object {
            @include iconStyles();
            background-image: url("../images/feature-object.svg");
            width: 200px;
            height: 140px;
        }
        .item {
            text-align: center;
            .icon {
                @include iconStyles();
                width: 180px;
                height: 180px;
                @media screen and (max-width: 768px) {
                    width: 120px;
                    height: 120px;
                }
            }
            .squares {
                background-image: url("../images/Limited.svg");
            }
            .folders {
                background-image: url("../images/Ownership.svg");
            }
            .medal {
                background-image: url("../images/Pricing.svg");
            }
            .check {
                background-image: url("../images/Distribution.svg");
            }
            .transparent {
                background-image: url("../images/transparent.svg");
            }
            .community {
                background-image: url("../images/Wallet.svg");
            }
            .voting {
                background-image: url("../images/Amulet.svg");
            }
            .drops {
                background-image: url("../images/Drop.svg");
            }
            .sec-heading4 {
                color: $n7;
                font-size: 28px;
                line-height: 1.4;
                margin: 0 0 16px;
                position: relative;
                left: 0px;
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                text-align: left;
                @media screen and (max-width: 768px) {
                    font-size: 20px;
                    text-align: center;
                }
            }
            p.description {
                position: relative;
                left: 0px;
                font-size: 17px;
                color: #6B7378;
                transition: all 0.5s;
                max-width: 240px;
                margin-bottom: 15px;
                text-align: left;
                line-height: 1.4;
                @media screen and (max-width: 768px) {
                    font-size: 13px;
                    text-align: center;
                }
            }
            a {
                color: #F2BF5A;
            }
        }
        &:hover {
            .sec-heading4 {
                left: 10px;
            }
            p.description {
                left: 10px;
            }
        }
    }
}

// Features section styles end

#AmuletsReveal {
    background-color: $n1;
    .reveal-row {
        &.underline {
            border-bottom: 1px solid #000000;
        }
        .reveal-item-book {
            width: 20px;
            flex: 0 0 20px;
        }
        .reveal-item-text {
            font-size: 13px;
            line-height: 1.4;
            margin-left: 15px;
            color: #A5823D;
        }
        a {
            font-size: 13px;
            color: $p6;
            line-height: 1.4;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }
    }
    
}

// Gallery section styles start

#gallery {
    background-color: $n1;
    position: relative;
    color: $n6;
    .float-img {
        position: absolute;
    }
    .object1 {
        @include iconStyles();
        background-image: url("../images/gallery-object.svg");
        top: 35%;
        left: 0;
        width: 397px;
        height: 712px;
    }
    .item-card {
        background: transparent linear-gradient(270deg, $n1 0%, $n2 60%) 0% 0% no-repeat padding-box;
        border-radius: 30px;
        margin: 0 10px;
        img {
            padding: 20px;
            border-radius: 40px;
            object-fit: contain;
        }
    }
    .rotate1 {
        transform: rotate(348deg);
    }
    .rotate2 {
        transform: rotate(355deg);
    }
    .rotate3 {
        transform: rotate(12deg);
    }
    .rotate4 {
        transform: rotate(358deg);
    }
}

// Gallery section styles end

// Pricing section styles start

#pricing {
    background-color: $n1;
    color: $n6;
    .float-img {
        position: absolute;
    }
    .sub-title {
        @include loraH1Styles();
        font-size: 20px;
        letter-spacing: 0.96px;
        margin-bottom: 16px;
        text-align: center;
    }
    .react-tabs__tab-list {
        width: fit-content;
        margin: auto;
        border: 1px solid rgb(165 130 61 / 40%);
        border-radius: 40px;
        padding: 4px;
        color: #A5823D;
        margin-bottom: 30px;
        .react-tabs__tab {
            padding: 15px 25px;
            font-size: 16px;
            bottom: 0;
            border: none;
            &:first-child {
                border-radius: 30px 0 0 30px;
            }
            &:first-child {
                border-radius: 0 30px 30px 0;
            }
            &.react-tabs__tab--selected {
                background: rgb(165 130 61 / 10%);
                border: none;
                color: $p6;
                &:first-child {
                    border-radius: 30px 0 0 30px;
                }
                &:last-child {
                    border-radius: 0 30px 30px 0;
                }
                .white {
                    color: #FFF;
                }
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid $p6;
                    position: absolute;
                    bottom: -12px;
                    left: auto;
                    right: 50%;
                    background: transparent;
                }
            }
        }
    }
    .series-content {
        background: #30373B;
        border-radius: 8px  8px 20px 20px;
        @media screen and (max-width: 768px) {
            border-radius: 16px 16px 40px 40px;
        }
        .logo-video {
            display: block;
            border-radius: 8px 0 0 20px;
            @media screen and (max-width: 768px) {
                border-radius: 16px 16px 0 0;
            }
        }
        .line {
            width: 100%;
            top: 50%;
            border-bottom: 1px solid #000;
            @media screen and (max-width: 991.98px) {
                display: none;
            }
        }
        .info {
            padding: 20px;
            .date-price {
                .sub-heading {
                    color: $n7;
                    font-size: 18px;
                }
                .heading {
                    color: $p6;
                    font-size: 36px;
                    &.white {
                        color: $n6;
                    }
                }
                @media screen and (max-width: 767.98px) {
                    .sub-heading {
                        font-size: 15px;
                    }
                    .heading {
                        font-size: 25px;
                    }
                }
                .price-wrapper {
                    background: #A5823D;
                    border-radius: 20px;
                    .price-box {
                        @media screen and (min-width: 768px) {
                            width: 180px;
                        }
                        @media screen and (max-width: 767.98px) {
                            flex-direction: column;
                            padding: 8px;
                        }
                        padding: 10px 20px;
                        label {
                            font-size: 13px;
                        }
                        .black {
                            color: #000;
                            @media screen and (max-width: 767.98px) {
                                display: none;
                            }
                        }
                        .price {
                            font-size: 18px;
                        }
                    }
                }
                .pop-label {
                    position: relative;
                    width: fit-content;
                    padding: 10px;
                    border: 1px solid;
                    border-radius: 20px;
                    font-size: 13px;
                    color: #A5823D;
                    .white {
                        color: #FFF;
                    }
                    .circle-icon {
                        display: inline-block;
                        vertical-align: bottom;
                        margin-right: 8px;
                    }
                    .gold {
                        color: #F2BF5A
                    }
                }
            }
            .progress-bar-wrapper {
                margin-top: 30px;
                @media screen and (min-width: 1200px) {
                    margin-top: 110px;
                }
                .pop-label {
                    position: relative;
                    width: fit-content;
                    margin: 15px auto;
                    padding: 10px;
                    border: 1px solid;
                    border-radius: 20px;
                    font-size: 13px;
                    color: #A5823D;
                    .white {
                        color: #FFF;
                    }
                    .circle-icon {
                        display: inline-block;
                        vertical-align: bottom;
                        margin-right: 8px;
                    }
                    &:after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        bottom: -1px;
                        left: -1px;
                        background: url("../images/cornor-icon.png");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                    }
                    &.right-icon:after {
                        background: url("../images/cornor-right-icon.png");
                        right: -1px;
                        left: auto;
                    }
                }
                .my-progress-bars {
                    display: flex;
                    justify-content: space-between;
                    .progress {
                        height: 16px;
                        background-color: #000;
                        border-radius: 16px;
                        .progress-bar {
                            background-color: $p6;
                            margin-right: 10px;
                            border-radius: 16px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .w-0 { width: 0%; }
                .w-1 { width: 1%; }
                .w-2 { width: 2%; }
                .w-3 { width: 3%; }
                .w-4 { width: 4%; }
                .w-5 { width: 5%; }
                .w-6 { width: 6%; }
                .w-7 { width: 7%; }
                .w-8 { width: 8%; }
                .w-9 { width: 9%; }
                .w-10 { width: 10%; }
                .w-11 { width: 11%; }
                .w-12 { width: 12%; }
                .w-13 { width: 13%; }
                .w-14 { width: 14%; }
                .w-15 { width: 15%; }
                .w-16 { width: 16%; }
                .w-17 { width: 17%; }
                .w-18 { width: 18%; }
                .w-19 { width: 19%; }
                .w-20 { width: 20%; }
                .w-21 { width: 21%; }
                .w-22 { width: 22%; }
                .w-23 { width: 23%; }
                .w-24 { width: 24%; }
                .w-25 { width: 25%; }
                .w-26 { width: 26%; }
                .w-27 { width: 27%; }
                .w-28 { width: 28%; }
                .w-29 { width: 29%; }
                .w-30 { width: 30%; }
                .w-31 { width: 31%; }
                .w-32 { width: 32%; }
                .w-33 { width: 33%; }
                .w-34 { width: 34%; }
                .w-35 { width: 35%; }
                .w-36 { width: 36%; }
                .w-37 { width: 37%; }
                .w-38 { width: 38%; }
                .w-39 { width: 39%; }
                .w-40 { width: 40%; }
                .w-41 { width: 41%; }
                .w-42 { width: 42%; }
                .w-43 { width: 43%; }
                .w-44 { width: 44%; }
                .w-45 { width: 45%; }
                .w-46 { width: 46%; }
                .w-47 { width: 47%; }
                .w-48 { width: 48%; }
                .w-49 { width: 49%; }
                .w-50 { width: 50%; }
                .w-51 { width: 51%; }
                .w-52 { width: 52%; }
                .w-53 { width: 53%; }
                .w-54 { width: 54%; }
                .w-55 { width: 55%; }
                .w-56 { width: 56%; }
                .w-57 { width: 57%; }
                .w-58 { width: 58%; }
                .w-59 { width: 59%; }
                .w-60 { width: 60%; }
                .w-61 { width: 61%; }
                .w-62 { width: 62%; }
                .w-63 { width: 63%; }
                .w-64 { width: 64%; }
                .w-65 { width: 65%; }
                .w-66 { width: 66%; }
                .w-67 { width: 67%; }
                .w-68 { width: 68%; }
                .w-69 { width: 69%; }
                .w-70 { width: 70%; }
                .w-71 { width: 71%; }
                .w-72 { width: 72%; }
                .w-73 { width: 73%; }
                .w-74 { width: 74%; }
                .w-75 { width: 75%; }
                .w-76 { width: 76%; }
                .w-77 { width: 77%; }
                .w-78 { width: 78%; }
                .w-79 { width: 79%; }
                .w-80 { width: 80%; }
                .w-81 { width: 81%; }
                .w-82 { width: 82%; }
                .w-83 { width: 83%; }
                .w-84 { width: 84%; }
                .w-85 { width: 85%; }
                .w-86 { width: 86%; }
                .w-87 { width: 87%; }
                .w-88 { width: 88%; }
                .w-89 { width: 89%; }
                .w-90 { width: 90%; }
                .w-91 { width: 91%; }
                .w-92 { width: 92%; }
                .w-93 { width: 93%; }
                .w-94 { width: 94%; }
                .w-95 { width: 95%; }
                .w-96 { width: 96%; }
                .w-97 { width: 97%; }
                .w-98 { width: 98%; }
                .w-99 { width: 99%; }
                .w-100 { width: 100%; }
            }
            .end-date {
                color: $n7;
                font-size: 13px;
                text-align: right;
                margin-top: 30px;
                @media screen and (min-width: 992px) {
                    margin-top: 22%;
                }
            }
            .etheum-details {
                margin-top: 30px;
                @media screen and (min-width: 1200px) {
                    margin-top: 90px;
                }
                .buy  {
                    .set-value {
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 767.98px) {
                            width: 100%;
                        }
                        .qty {
                            padding: 24px 14px;
                            font-size: 13px;
                            background: $n1;
                            color: $n7;
                            border-radius: 8px;
                            @media screen and (max-width: 767.98px) {
                                padding: 20px 14px;
                            }
                        }
                        .value-btns {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: $n1;
                            border-radius: 8px;
                            padding: 16px;
                            margin: 0 5px;
                            @media screen and (max-width: 767.98px) {
                                padding: 12px;
                                width: calc( 100% - 160px );
                            }
                            @media screen and (min-width: 1400px) {
                                width: 200px;
                            }
                            #count {
                                margin: 0 10px 4px;
                                font-size: 18px;
                            }
                        }
                        button:not(.max-btn) {
                            background-color: transparent;
                            border: 0;
                        }
                        .icon  {
                            @include iconStyles();
                            width: 24px;
                            height: 24px;
                        }
                        .minus {
                            background-image: url("../images/minus.svg");
                        }
                        .plus {
                            background-image: url("../images/plus.svg");
                        }
                        .max-btn {
                            padding: 20px 25px;
                            font-size: 13px;
                            background: $n1;
                            color: #F2BF5A;
                            border-radius: 8px;
                            text-transform: uppercase;
                            @media screen and (max-width: 767.98px) {
                                padding: 16px 25px;
                            }
                        }
                    }
                    .secondary-market .label {
                        font-size: 18px;
                        color: $n7;
                        text-align: left;
                        line-height: 1.6;
                        @media screen and (max-width: 1199.98px) {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                    }
                    .my-btn1 {
                        font-size: 18px;
                        @media screen and (max-width: 767.98px) {
                            width: 100%;
                            padding: 10px;
                        }
                        .white {
                            color: #fff;
                        }
                        .small {
                            font-size: 13px;
                            color: #A5823D;
                        }
                        .arrow-buy {
                            display: inline-block;
                            width: 30px;
                            margin-left: 10px;
                        }
                        &.opensea-link {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 14px 30px;
                            .opensea-icon {
                                width: 30px;
                                height: 30px;
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.line-divider-wrapper {
    background: $n1;
    .line-divider {
        border-bottom: 1px solid #000;
    }    
}

#check-past {
    background: $n1;
    color: $n6;
    .info-box {
        .info {
            background: #30373B;
            height: 100%;
            padding: 20px;
            position: relative;
            .box-title {
                font-size: 25px;
                line-height: 1.4;
                color: $n6;
                @media screen and (max-width: 767.98px) {
                    font-size: 15px;
                }
            }
            .box-label {
                color: #A5823D;
                font-size: 18px;
                @media screen and (max-width: 767.98px) {
                    font-size: 13px;
                }
            }
            .box-label-small {
                color: #F2BF5A;
                font-size: 15px;
                @media screen and (max-width: 767.98px) {
                    font-size: 13px;
                }
            }
            .box-form {
                #token_id {
                    width: 100%;
                    padding: 20px;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid #6B7378;
                    color: $n6;
                    font-size: 16px;
                }
                .set-value {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 767.98px) {
                        width: 100%;
                    }
                    .qty {
                        padding: 24px 14px;
                        font-size: 13px;
                        background: $n1;
                        color: $n7;
                        border-radius: 8px;
                        @media screen and (max-width: 767.98px) {
                            padding: 20px 14px;
                        }
                    }
                    .value-btns {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: $n1;
                        border-radius: 8px;
                        padding: 16px;
                        margin: 0 5px;
                        @media screen and (max-width: 767.98px) {
                            padding: 12px;
                            width: calc( 100% - 160px );
                        }
                        @media screen and (min-width: 1400px) {
                            width: 200px;
                        }
                        #count {
                            color: $n6;
                            margin: 0 10px 4px;
                            font-size: 18px;
                        }
                    }
                    button:not(.max-btn) {
                        background-color: transparent;
                        border: 0;
                    }
                    .icon  {
                        @include iconStyles();
                        width: 24px;
                        height: 24px;
                    }
                    .minus {
                        background-image: url("../images/minus.svg");
                    }
                    .plus {
                        background-image: url("../images/plus.svg");
                    }
                    .max-btn {
                        padding: 20px 25px;
                        font-size: 13px;
                        background: $n1;
                        color: #F2BF5A;
                        border-radius: 8px;
                        text-transform: uppercase;
                        @media screen and (max-width: 767.98px) {
                            padding: 16px 25px;
                        }
                    }
                }
                .my-btn1 {
                    @media screen and (max-width: 767.98px) {
                        width: 100%;
                    }
                    .arrow-claim {
                        display: none;
                        width: 24px;
                        margin-left: 10px;
                        @media screen and (max-width: 767.98px) {
                            display: inline-block;
                        }
                    }
                }
            }
            .box-form-result {
                color: $n7;
                line-height: 1.4;
                font-size: 13px;
                /*
                @media screen and (max-width: 767.98px) {
                    position: absolute;
                    top: 0;
                    right: 15px;
                    text-align: right!important;
                }
                */
            }
            .box-bottom-label {
                color: #F2BF5A;
                line-height: 1.4;
                font-size: 13px;
                @media screen and (max-width: 767.98px) {
                    position: absolute;
                    top: 0;
                    right: 15px;
                    text-align: right!important;
                }
            }
        }
        
    }
}

// Pricing section styles end

// Tweets-posts section styles start

#tweets-posts {
    background-color: $n1;
    position: relative;
    color: $n6;
    .social-heading {
        border-bottom: 1px solid #000;
        .col-lg-6:first-child {
            padding-left: 0;
        }
        .col-lg-6:last-child {
            padding-right: 0;
        }
        .sec-heading {
            color: $n7;
        }
    }
    .tweet,
    .post {
        border-radius: 8px;
        img {
            border-radius: 8px;
            border: 1px solid $n3;
            margin-bottom: 20px;
            width: 100%;
        }
    }
    #posts {
        margin-top: 80px;
    }
}

// Tweets-posts section styles end

// Media queries start

@media screen and (max-width: 1200px) {
    #pricing .info {
        padding: 40px 20px 40px 0;
    }
    #pricing .info .etheum-details .buy .set-value {
        margin-bottom: 20px;
    }
    #pricing .info .etheum-details .buy {
        flex-direction: column;
    }
}

@media screen and (max-width: 991.98px) {
    #crypto .object1 {
        width: 700px;
        height: 490px;
    }
    #crypto .item {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 767.98px) {
    #crypto .object1 {
        width: 450px;
        height: 315px;
    }
    #pricing .info {
        padding: 40px 15px 40px 15px;
    }
    #pricing .info .date-price .date {
        margin-bottom: 50px;
    }
    #pricing .info .date-price .price * {
        text-align: left;
    }    
    #pricing .info .etheum-details .buy {
        margin-top: 30px;
    }
    #pricing .info .etheum-details .buy .my-btn2 {
        margin-top: 0;
    }
    #tweets p.description,
    #posts p.description {
        margin-bottom: 40px;
    }
    .order1-on-mobile {
        order: 1;
    }
    .order2-on-mobile {
        order: 2;
    }
    .order3-on-mobile {
        order: 3;
    }
    .order4-on-mobile {
        order: 4;
    }
    .order1-on-mobile .text-right {
        text-align: left !important;
    }
    #crypto .arrow-icon {
        margin: 30px 0 0;
    }
    #gallery .my-btn1 {
        margin-top: 10px;
    }
}

@media screen and (max-width: 575.98px) {
    #pricing .info .etheum-details {
        flex-direction: column;
    }

    #crypto .object1 {
        width: 300px;
        height: 210px;
    }
    #crypto .item {
        flex-direction: column;
    }
    #crypto .item .left-side,
    #crypto .item .right-side {
        flex: 1 1 100%;
    }
    #crypto .item .left-side {
        order: 2;
    }
    #crypto .item .right-side {
        order: 1;
    }
    #crypto .item .right-side img {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
    #gallery .object1 {
        width: 300px;
        height: 712px;
    }
    #gallery .my-btn1 {
        margin-top: 10px;
    }
    #tweets-posts .twitter {
        top: 0;
        width: 300px;
        height: 850px;
    }
    #tweets-posts .group {
        bottom: 42%;
    }
    #tweets-posts .facebook {
        width: 155px;
        height: 300px;
    }
    #pricing .info .date-price .time .box::after {
        margin: 0 14px;
    }
    #pricing .info .date-price .time .box,
    #pricing .info .date-price .price .etheum .value {
        font-size: 24px;
    }
    #pricing .info .date-price .price .etheum .etheum-icon {
        height: 24px;
        margin-right: 5px;
    }
    #pricing .info .labels div {
        font-size: 10px;
    }
}

.dropdown-menu {
    background: red;
    width: 200px;
    position: absolute;
    top: 55px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background: #1888ff;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: #5cabff;
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}

@media screen and (max-width: 991.98px) {
    .fa-caret-down {
        display: none;
    }
}
// Media queries end


// Roadmaps section styles start

#roadmaps {
    background-color: $n1;
    position: relative;
    color: $n6;
    .roadmap-inner {
        .roadmap-item {
            width: 20%;
            padding: 0 10px;
            .roadmap-item-point {
                position: relative;
                margin-bottom: 12px;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 44px);
                    border-top: 1px dashed hsla(0,0%,100%,.3);
                    right: 0;
                    top: 12px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 44px);
                    border-top: 1px solid #fff;
                    right: 0;
                    top: 12px;
                    opacity: 0;
                    transition: opacity .35s;
                }
                span {
                    display: inline-block;
                    background: hsla(0,0%,100%,.1);
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    position: relative;
                    z-index: 2;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        background: #fff;
                        display: inline-block;
                        height: 10px;
                        border-radius: 50%;
                        left: 7px;
                        top: 7px;
                    }
                }
            }
            &:hover .roadmap-item-point:after {
                opacity: 1;
            }
            .roadmap-item-number {
                font-size: 24px;
                line-height: 28px;
            }
            .roadmap-item-text {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
    &.home-roadmap {
        .bottom-bar {
            position: absolute;
            width: 100%;
            border-bottom: 1px solid #000000;
            bottom: 0;
            @media screen and (max-width: 767.98px) {
                display: none;
            }
        }
        .roadmap-inner {
            .roadmap-item {
                margin-top: 20px;
                flex-direction: column;
                @media screen and (min-width: 992px) {
                    flex-direction: row;
                    &:first-child {
                        margin-top: 200px;
                    }
                    &:nth-child(2) {
                        margin-top: 150px;
                    }
                    &:nth-child(3) {
                        margin-top: 100px;
                    }
                    &:nth-child(4) {
                        margin-top: 50px;
                    }
                    &:last-child {
                        margin-top: 0;
                    }
                }
                .roadmap-item-point {
                    position: relative;
                    margin-bottom: 0;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 50%;
                        height: 1px;
                        background: linear-gradient(270deg, rgba(242,191,90,1) 0%, rgba(242,191,90,0.01) 100%);
                        right: 50%;
                        top: 15px;
                        opacity: 0.4;
                        border: none;
                        @media screen and (min-width: 992px) {
                            width: 1px;
                            height: 100%;
                            top: 20px;
                            background: linear-gradient(180deg, rgba(242,191,90,1) 0%, rgba(242,191,90,0.01) 100%);
                        }
                    }
                    span {
                        display: inline-block;
                        background: transparent;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        position: relative;
                        z-index: 2;
                        &::before {
                            content: "";
                            position: absolute;
                            width: 10px;
                            background: #A5823D;
                            display: inline-block;
                            height: 10px;
                            border-radius: 50%;
                            left: 10px;
                            top: 10px;
                        }
                    }
                }
                .roadmap-item-number {
                    font-size: 24px;
                    line-height: 28px;
                    color: $p6;
                }
                .roadmap-item-text {
                    font-size: 16px;
                    line-height: 22px;
                    color: $n7;
                    max-width: 320px;
                    margin: auto;
                    p {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    &.about-roadmap {
        .roadmap-inner {
            .roadmap-item {
                width: 25%;
                padding: 0 10px;
                position: relative;
                .roadmap-item-point {
                    position: relative;
                    margin-bottom: 20px;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        border-top: 1px solid rgba(0,0,0);
                        right: -20px;
                        top: 10px;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        border-top: 1px dashed #A5823D;
                        right: -20px;
                        top: 10px;
                        opacity: 0;
                        transition: opacity 0.35s;
                    }
                    span {
                        display: inline-block;
                        background: #F2BF5A;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        position: relative;
                        z-index: 2;
                        &::before {
                            display: none;
                        }
                    }
                }
                &:hover .roadmap-item-point:after {
                    opacity: 1;
                }
                .roadmap-item-number {
                    color: $n7;
                    font-size: 24px;
                    line-height: 28px;
                    margin-left: 10px;
                }
                &:hover .roadmap-item-number {
                    color: $n6;
                }
                .roadmap-item-text {
                    color: #A5823D;
                    font-size: 18px;
                    line-height: 22px;
                    margin-left: 10px;
                }
                @media screen and (max-width: 991.98px) {
                    position: relative;
                    width: 100%;
                    padding: 0;
                    .roadmap-item-point {
                        height: 60px;
                        &:before {
                            border-top: none;
                            border-right: 1px solid black;
                            right: 50%;
                            top: 20px;
                            height: 100%;
                        }
                    }
                    &:last-child .roadmap-item-point::before {
                        display: none;
                    }
                    .roadmap-item-text {
                        position: absolute;
                        top: -10px;
                        left: 55%;
                        font-size: 13px;
                    }
                    .roadmap-item-number {
                        position: absolute;
                        top: 5px;
                        left: 55%;
                        font-size: 15px;
                        color: #fff;
                    }
                    &:nth-child(even) {
                        .roadmap-item-text, .roadmap-item-number {
                            left: auto;
                            right: 58%;
                        }
                    }
                }
            }
        }
        &.about-s1 {
            .roadmap-inner {
                .roadmap-item {
                    &:first-child {
                        .roadmap-item-point {
                            span {
                                width: 20px;
                                height: 20px;
                                margin: -5px 0;
                                @media screen and (max-width: 991.98px) {
                                    width: 20px;
                                    height: 20px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                    &:last-child {
                        .roadmap-item-point {
                            span {
                                width: 20px;
                                height: 20px;
                                border-radius: 0;
                                margin: -1px 0;
                                @media screen and (max-width: 991.98px) {
                                    width: 20px;
                                    height: 20px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.about-s2 {
            .roadmap-inner {
                .roadmap-item {
                    width: 20%;
                    @media screen and (max-width: 991.98px) {
                        width: 100%;
                    }
                    &:last-child {
                        .roadmap-item-point {
                            span {
                                width: 22px;
                                height: 22px;
                                border-radius: 0;
                                margin: -1px 0;
                                @media screen and (max-width: 991.98px) {
                                    width: 10px;
                                    height: 10px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Roadmaps media queries start

@media screen and (max-width: 991.98px) {
    #roadmaps {
        .roadmap-inner {
            .roadmap-item {
                width: 100%;
                padding: 0;
                text-align: center;
                .roadmap-item-point:before {
                    width: 100%;
                }
                .roadmap-item-point:after {
                    display: none;
                }
            }
        }
    }
}

// Roadmaps media queries end

// Roadmaps section styles end
