
#nft-detail {
    padding: 40px 0 20px;
    background-color: $n1;
    position: relative;
    color: $n6;
    .my-breadcrumb {
        margin-bottom: 40px;
        .breadcrumb {
            background-color: transparent;
            padding: 0;
            align-items: center;
            .breadcrumb-item {
                font-family: $lora-font-family;
                a {
                    color: $n4;
                    font-size: 16px;
                    line-height: 22px;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .breadcrumb-item.active {
                color:  $n6;
            }
            .breadcrumb-item + .breadcrumb-item::before {
                float: left;
                padding-right: .5rem;
                color: $n4;
                content: ">";
            }
        }
    }
    .main-card{
        .card-img {
            img {
                object-fit: cover;
                width: 100%;
            }
        }
        .right-side {
            background: #000;
            .token {
                display: flex;
                align-items: baseline;
                p {
                    font-size: 18px;
                    line-height: 23px;
                    margin-right: 10px;
                }
                #token-id {
                    width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .window-icon {
                    @include iconStyles();
                    background-image: url("../images/copy.svg");
                    width: 16px;
                    height: 16px;
                }
            }
            .share-icon{
                @include iconStyles();
                background-image: url("../images/share.svg");
                width: 24px;
                height: 24px;
            }
            .purchase {
                margin-top: 30px;
                .title {
                    font-size: 18px;
                    line-height: 23px;
                    color: $p4;
                    margin-bottom: 2px;
                }
                .box {
                    display: flex;
                    align-items: baseline;
                    margin-top: 5px;
                    .etherum-icon {
                        @include iconStyles();
                        background-image: url("../images/ethereum2.svg");
                        width: 18px;
                        height: 32px;
                        margin-right: 16px;
                    }
                    .price {
                        font-size: 48px;
                        line-height: 55px;
                        text-transform: uppercase;
                    }
                    .amount {
                        font-size: 24px;
                        line-height: 28px;
                        color: #CDCDCD;
                        margin-left: 10px;
                    }
                }
            }
            .chain-info {
                .gold {
                    color: $p1;
                    width: 160px;
                    flex: 0 0 160px;
                }
                .dark {
                    color: $n7;
                    p {
                        font-size: 13px;
                    }
                }
                .underline {
                    border-bottom: 1px solid #192024;
                }
            }
            .link-icon {
                display: inline-block;
                width: 26px;
                margin-left: 10px;
            }
            .details {
                h3 {
                    margin-bottom: 24px;
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.64px;
                }
            }
        }
    }
    .attributes {
        h2 {
            margin: 0;
            margin-bottom: 50px;
        }
        .underline {
            border-bottom: 1px solid #000;
        }
        .info-icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 1px solid #A5823D;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .item-box {
            display: flex;
            align-items: center;
            color: $n6;
            padding: 20px 0;
            margin-bottom: 30px;
            border-bottom: 1px solid #000;
            .ball-icon {
                @include iconStyles();
                background-image: url("../images/circle-icon.svg");
                width: 14px;
                height: 14px;
            }
            .info {
                margin-left: 25px;
                .legend {
                    font-size: 18px;
                    line-height: 1.4;
                    color: $p1;
                }
                .name {
                    text-transform: capitalize;
                    font-size: 18px;
                    line-height: 1.4;
                }
            }
        }
    }
    h2 {
        @include ubuntuH2Styles();
        font-size: 50px;
        line-height: 1.6;
        text-transform: capitalize;
    }
    h3 {
        @include ubuntuH3Styles();
        font-size: 36px;
    }
    h5 {
        @include ubuntuH5Styles();
        font-size: 18px;
        color: #F2BF5A;
        margin-top: 0px;
    }
}

@media screen and (max-width: 575.98px) {
    #nft-detail h2, #nft-detail h3 {
        font-size: 21px;
    }
    #nft-detail .attributes .item-box {
        padding: 10px 0;
        margin-bottom: 10px;
        .info {
            .legend, .name {
                font-size: 13px;
            }
        }
    }
    #nft-detail .attributes .info-icon {
        width: 30px;
        height: 30px;
    }
}